<template>
  <div class="popup-add-comment">
    <div class="popup-add-comment__wrapper">
      <Column :class="$viewport.desktop ? 'grid-6' : ''">
        <Row class="popup-add-comment__row">
          <p class="popup-add-comment__title">{{$popup.additions.title}}</p>
        </Row>
        <Column class="popup-add-comment__row">
          <Row>
            <p class="popup-add-fact__label">Ваша оценка</p>
          </Row>
          <Row>
            <Icon v-for="(star, index) in 5"
                  class="popup-add-comment__icon"
                  :class="index < commentRating ? 'popup-add-comment__icon-filled' : ''"
                  :key="star.id"
                  v-on:click.native="changeRating(index + 1)"
                  xlink="star"
                  :width="30"
                  :height="30"
                  viewport="0 0 20 20"/>
          </Row>
        </Column>
        <Row align="center"
             justify="between">
          <label class="popup-add-fact__label"
                 for="factDescription">Ваш отзыв</label>
          <span class="popup-add-fact__textarea-counter">
            Осталось {{textareaCount}} символов</span>
        </Row>
        <textarea class="popup-add-fact__input"
                  placeholder="Расскажите об объекте"
                  id="factDescription"
                  :maxlength="textareaMaxCount"
                  v-model="commentMessage"/>
        <div class="popup-add-comment__row">
          <Button color="green"
                  :disabled="disabled"
                  v-on:click.native="addComment"
                  class="popup-add-comment__button">отправить отзыв
          </Button>
        </div>
        <p class="popup-add-comment__terms-conditions">Я принимаю
          <span class="popup-add-comment__terms-conditions-link"
                v-on:click="openPopupTermsConditions">
            правила и условия
          </span>
          , публикуя свой отзыв.
        </p>
      </Column>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PopupAddComment',
  data() {
    return {
      disabled: true,
      textareaMaxCount: 700,
      commentRating: 0,
      commentMessage: '',
    };
  },
  computed: {
    textareaCount() {
      return this.textareaMaxCount - this.commentMessage.length;
    },
  },
  methods: {
    openPopupTermsConditions() {
      this.$store.commit('CLOSE_POPUP');
      this.$store.commit('OPEN_POPUP', {
        width: this.$viewport.desktop ? 800 : '100%',
        height: this.$viewport.desktop ? 'auto' : '100vh',
        type: 'PopupTermsConditions',
        horizontal: 'center',
        vertical: 'center',
      });
    },
    checkAllConditions() {
      this.disabled = !(this.commentMessage.length > 0 && this.commentRating > 0);
    },
    addComment() {
      this.$http.post(`/api/${this.$popup.additions.page}/${this.$route.params.id}/ratings?value=${this.commentRating}`);
      this.$http.post(`/api/${this.$popup.additions.page}/${this.$route.params.id}/comments?comment=${this.commentMessage}`)
        .then(() => {
          this.$store.commit('OPEN_POPUP', {
            width: this.$viewport.desktop ? 800 : '100%',
            height: '100vh',
            type: 'PopupSuccessfulAddReview',
            horizontal: 'right',
          });
        });
      this.commentMessage = '';
      this.commentRating = 0;
    },
    changeRating(value) {
      if (this.commentRating === value) {
        this.commentRating = 0;
      } else {
        this.commentRating = value;
      }
    },
  },
  watch: {
    commentMessage() {
      this.checkAllConditions();
    },
    commentRating() {
      this.checkAllConditions();
    },
  },
};
</script>
